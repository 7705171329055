import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"

import { LangContext } from "../../context/LangContext"

import ImgNavbarLogoDark from "../../assets/img/brand/logo_bsd-dark.png"
import ImgNavbarLogoLight from "../../assets/img/brand/logo_bsd-light.png"

const Navbar = ({ newsSlug, isAmp, disableEffect }) => {
  const { lang, setLang } = useContext(LangContext)

  const data = useStaticQuery(graphql`
    query {
      wordPress {
        menus {
          nodes {
            name
            slug
            menuItems {
              nodes {
                url
                label
                childItems {
                  nodes {
                    label
                    url
                    childItems {
                      nodes {
                        label
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // Navbar expand config
  // Change the const below & config on _navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl
  const navExpand = "lg"

  // Search - MOBILE
  // Open / close menu state & handler
  const [search, setSearch] = useState(false)

  const HandleSearch = () => {
    setSearch(!search)
  }

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  const [megaMenu, setMegaMenu] = useState(false)
  const handleMenuMobile = () => {
    disabledMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }
  // Toggler disabled state & handler
  const [disabled, setDisabled] = useState(false)
  const disabledMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1000)
  }
  // Onscroll
  const [navScroll, setNavScroll] = useState(false)
  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const containsClass = navbar.classList.contains("light")

    window.onscroll = function() {
      if (!containsClass) {
        if (window.pageYOffset > 5) {
          setNavScroll(true)
        } else {
          setNavScroll(false)
        }
      }
    }
  }, [])

  return (
    <>
      <div className="navbar-wrapper fixed-top">
        {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
        {isAmp ? (
          <>
            <header>
              <amp-mega-menu height="60" layout="fixed-height">
                <nav
                  className={`navbar navbar-expand-${navExpand} transparent dark 
                  ${navScroll ? "is-scrolled" : ""} ${
                    megaMenu ? "nav-menu-active" : ""
                  }`}
                >
                  <ul class="navbar-nav ml-auto">
                    <li>
                      <Link
                        className="navbar-brand"
                        to={lang === "EN" ? "/" : "/id"}
                      >
                        <amp-img
                          src={ImgNavbarLogoDark}
                          class="img-fluid dark"
                          alt="Logo"
                          width="70"
                          height="30"
                        />
                        {/* <amp-img
                          src={ImgNavbarLogoLight}
                          class="img-fluid light"
                          alt="Logo"
                          width="85"
                          height="40"
                        /> */}
                      </Link>
                    </li>
                    <li class="navbar-toggler">
                      <div
                        role="button"
                        on="tap:sidebar1.toggle"
                        tabindex="0"
                        class="hamburger humburger1"
                      >
                        &#x2630;
                      </div>
                    </li>
                    <li class="login-toggler">
                      <Link
                        className="btn btn-secondary pb-1 pt-1 login text-capitalize btn-login"
                        to="/login/"
                      >
                        {lang === "ID" ? "Masuk" : "Login"}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </amp-mega-menu>
            </header>
            <MobileMenu
              mobileMenu={mobileMenu}
              navExpand={navExpand}
              data={data}
              newsSlug={newsSlug}
              isAmp={isAmp}
            />
          </>
        ) : (
          <>
            <nav
              className={`navbar navbar-expand-${navExpand} transparent dark 
              ${navScroll || disableEffect ? "is-scrolled" : ""} ${
                megaMenu ? "nav-menu-active" : ""
              }`}
            >
              <div className="container position-relative">
                <Link className="navbar-brand" to={lang === "EN" ? "/" : "/id"}>
                  <img
                    src={ImgNavbarLogoDark}
                    className="img-fluid dark"
                    alt="Logo"
                    width="auto"
                  />
                  <img
                    src={ImgNavbarLogoLight}
                    className="img-fluid light"
                    alt="Logo"
                    width="auto"
                  />
                </Link>
                <div className="navbar-toggler">
                  <div className="d-block d-md-none">
                    {/* prettier-ignore */}
                    <button className="nav-link nav-link-icon search-toggler" aria-label="toggler-search" onClick={HandleSearch}>
                      <i className="fal fa-search icon-search" />
                    </button>
                  </div>
                  <button
                    aria-label="toggler-menu"
                    className=""
                    disabled={disabled}
                    onClick={handleMenuMobile}
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>

                <DesktopMenu
                  navExpand={navExpand}
                  megaMenu={megaMenu}
                  setMegaMenu={setMegaMenu}
                  setNavScroll={setNavScroll}
                  data={data}
                />
                <MobileMenu
                  mobileMenu={mobileMenu}
                  navExpand={navExpand}
                  data={data}
                  newsSlug={newsSlug}
                  handleMenuMobile={handleMenuMobile}
                />
              </div>
            </nav>
            <form
              action="/search-result"
              className={`form-search mobile d-block d-md-none ${search &&
                "active"}`}
            >
              <input
                className="form-control"
                name="key"
                id="key"
                type="text"
                placeholder={
                  lang == "EN"
                    ? "Type to search"
                    : "Masukkan kata kunci pencarian"
                }
              />
              <i class="fal fa-times" onClick={HandleSearch}></i>
            </form>
          </>
        )}
      </div>
    </>
  )
}

export default Navbar
