import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Button } from "../buttons/buttons"
import { ImgRatioAmp, Link as AntiLink } from "../utils/utils"
import ReactMarkdown from "react-markdown"

import { ImgRatio, Link, BackgroundImage, replaceUnicode } from "../utils/utils"
import ratio11 from "../utils/img/r1-1.png"
import ratio21 from "../utils/img/r2-1.png"
import ratio32 from "../utils/img/r3-2.png"
import { LangContext } from "../../../context/LangContext"

export const Card = ({
  id,
  forwardKey,
  forwardRef,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text = "",
  textClassName,
  icon,
  cardIconClassName,
  iconWidth,
  img,
  imgHeight,
  imgLabel,
  imgLabelClassName,
  imgLabelOverlay,
  imgOverlay,
  link,
  textButton,
  target,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
  titlePrefix,
  fixedHeight
}) => {
  const { lang } = useContext(LangContext)
  const getCategoryProjectIcon = text => {
    let categoryIcon = ""
    if (lang === "EN") {
      if (text.toLowerCase() === "residential") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "cluster") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "apartment") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "office") {
        categoryIcon = "far fa-building"
      } else if (text.toLowerCase() === "bsd business central district") {
        categoryIcon = "far fa-building"
      } else if (text.toLowerCase() === "commercial") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "bookstore") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "cullinary") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "mall") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "marketplace") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "shophouse") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "industrial") {
        categoryIcon = "fas fa-building"
      } else if (text.toLowerCase() === "facilities") {
        categoryIcon = "far fa-building"
      } else {
        categoryIcon = ""
      }
    } else {
      if (text.toLowerCase() === "(id) residential") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "cluster") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "apartment") {
        categoryIcon = "fal fa-home-lg-alt"
      } else if (text.toLowerCase() === "office") {
        categoryIcon = "far fa-building"
      } else if (text.toLowerCase() === "bsd business central district") {
        categoryIcon = "far fa-building"
      } else if (text.toLowerCase() === "commercial") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "bookstore") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "cullinary") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "mall") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "marketplace") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "shophouse") {
        categoryIcon = "fas fa-city"
      } else if (text.toLowerCase() === "industrial") {
        categoryIcon = "fas fa-building"
      } else if (text.toLowerCase() === "facilities") {
        categoryIcon = "far fa-building"
      } else {
        categoryIcon = ""
      }
    }
    return categoryIcon
  }

  const categoryProjectIcon = getCategoryProjectIcon(text ? text : "")
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      target={target}
      id={id}
      key={forwardKey}
      ref={forwardRef}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {icon && (
            <div className={`card-icon ${cardIconClassName}`}>
              <img
                src={icon}
                className={`img-fluid card-icon-img ${iconWidth}`}
                alt={title || "Icon"}
              />
              <div className={`el-after`} />
            </div>
          )}
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatio />
              </BackgroundImage>
              <div className={`el-after`} />
              {imgLabel && <div className="image-label">{imgLabel}</div>}
              {imgLabelOverlay && <div className="overlay"></div>}
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text || fixedHeight) && (
            <div className={`card-body ${cardBodyClassName} ${fixedHeight ? 'fixed-height': ''}`}>
              {label && (
                <p className={`card-label ${labelClassName}`}>{label}</p>
              )}
              {title && (
                <div className="d-flex align-items-center">
                  {titlePrefix}
                  <h3
                    className={`card-title ${titleClassName}`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h3>
                </div>
              )}
              {text && (
                <div
                  className="detail-category"
                  style={{ display: "inline-flex" }}
                >
                  {categoryProjectIcon && (
                    <i
                      className={categoryProjectIcon}
                      style={{ color: "#e3e3e3", lineHeight: "1.5" }}
                    >
                      &nbsp;
                    </i>
                  )}
                  <ReactMarkdown
                    source={text}
                    escapeHtml={false}
                    className={`card-text ${textClassName} `}
                  />
                </div>
              )}
              {textButton && (
                <div>
                  <Button variant="link" link={link} target={target}>
                    {textButton}
                  </Button>
                </div>
              )}

              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

Card.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  imgLabel: PropTypes.string,
  imgLabelClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string,
  target: PropTypes.string,
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  isTextElipsis: PropTypes.bool
}

Card.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  imgLabelClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  target: null,
  className: "",
  imgLabelOverlay: false,
  isTextElipsis: false
}

export const CardAmp = ({
  id,
  forwardKey,
  forwardRef,
  variant,
  colLeft,
  colRight,
  label,
  labelClassName,
  title,
  titleClassName,
  text,
  textClassName,
  icon,
  cardIconClassName,
  iconWidth,
  img,
  imgHeight,
  imgLabel,
  imgLabelClassName,
  imgLabelOverlay,
  imgOverlay,
  link,
  textButton,
  target,
  cardBgClassName,
  cardBodyClassName,
  className,
  children,
}) => {
  return (
    <Link
      to={link}
      className={`card card-${variant} ${className}`}
      target={target}
      id={id}
      key={forwardKey}
      ref={forwardRef}
    >
      <div className="row row-0">
        <div className={`col-bg ${colLeft}`}>
          {icon && (
            <div className={`card-icon ${cardIconClassName}`}>
              <amp-img
                src={icon}
                class={`img-fluid card-icon-img ${iconWidth}`}
                alt={title || "Icon"}
                width="50"
                height="50"
              />
              <div className={`el-after`} />
            </div>
          )}
          {img && (
            <div className={`card-bg ${cardBgClassName}`}>
              <BackgroundImage
                Tag="div"
                fluid={img}
                className={`card-bg-img ${imgHeight}`}
              >
                <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                <ImgRatioAmp />
              </BackgroundImage>
              <div className={`el-after`} />
              {imgLabel && <div className="image-label">{imgLabel}</div>}
              {imgLabelOverlay && <div className="overlay"></div>}
            </div>
          )}
        </div>
        <div className={`col-body ${colRight}`}>
          {(title || text) && (
            <div className={`card-body ${cardBodyClassName}`}>
              {label && (
                <p className={`card-label ${labelClassName}`}>{label}</p>
              )}
              {title && (
                <h3 className={`card-title ${titleClassName}`}>
                  {replaceUnicode(title)}
                </h3>
              )}
              {text && (
                <ReactMarkdown
                  source={text}
                  escapeHtml={false}
                  className={`card-text ${textClassName}`}
                />
              )}
              {textButton && (
                <div>
                  <Button variant="link" link={link} target={target}>
                    {textButton}
                  </Button>
                </div>
              )}

              {children}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

CardAmp.propTypes = {
  id: PropTypes.string, // Set card id
  variant: PropTypes.oneOf(["basic", "boxless", "overlay"]), // Set card variant
  colLeft: PropTypes.string, // Set col left utilities classes (e.g. .col-md-6, .col-lg-4, etc.)
  colRight: PropTypes.string, // Set col right utilities classes (e.g. .col-md-6, .col-lg-8, etc.)
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  imgLabel: PropTypes.string,
  imgLabelClassName: PropTypes.string,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .card-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  link: PropTypes.string,
  target: PropTypes.string,
  cardBgClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

CardAmp.defaultProps = {
  id: "",
  variant: "basic",
  colLeft: "col-12",
  colRight: "col-12",
  label: "",
  labelClassName: "",
  titleClassName: "",
  textClassName: "",
  imgLabelClassName: "",
  cardBgClassName: "",
  cardBodyClassName: "",
  imgHeight: "h-ratio-3-2",
  imgOverlay: "bottom",
  link: "",
  target: null,
  className: "",
  imgLabelOverlay: false,
}

export const CardProject = ({
  title,
  description,
  img,
  link,
  area,
  category,
  location,
  className,
  target,
}) => {
  return typeof img === "string" ? (
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <div
          alt={title}
          className="card card-project"
          style={{
            background: `url(${img}) no-repeat center`,
            backgroundSize: "cover",
          }}
        >
          <div className="card-body">
            <h3 className="card-title">{replaceUnicode(title)}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{area} hectares</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="mr-2 far fa-map"></i> <span>{location}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{area} hectares</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="mr-2 far fa-map"></i> <span>{location}</span>
                </div>
              )}
            </div>
            <p
              className="card-text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <button className="btn btn-link text-white fx-underline">
              Explore More
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={ratio11}
            alt="Placeholder"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={ratio21}
            alt="Placeholder"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={ratio32}
            alt="Placeholder"
          />
        </div>
      </AntiLink>
    </div>
  ) : (
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <BackgroundImage alt={title} className="card card-project" fluid={img}>
          <div className="card-body">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="mr-2 far fa-building"></i> <span>{area}</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="mr-2 far fa-map"></i> <span>{location}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className="mr-2 far fa-building"></i>{" "}
                  <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="mr-2 far fa-building"></i> <span>{area}</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="mr-2 far fa-map"></i> <span>{location}</span>
                </div>
              )}
            </div>
            <p className="card-text">{description}</p>
            <button className="btn btn-link text-white fx-underline">
              Explore More
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={ratio11}
            alt="Placeholder"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={ratio21}
            alt="Placeholder"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={ratio32}
            alt="Placeholder"
          />
        </BackgroundImage>
      </AntiLink>
    </div>
  )
}
