import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import GatsbyLink from "gatsby-link"
import GatsbyBackgroundImage from "gatsby-background-image"

import ratio11 from "./img/r1-1.png"
import ratio21 from "./img/r2-1.png"
import ratio32 from "./img/r3-2.png"

// Image Ratio
export const ImgRatio = () => {
  return (
    <>
      <img
        className="img-ratio img-ratio-1-1"
        src={ratio11}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <img
        className="img-ratio img-ratio-2-1"
        src={ratio21}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <img
        className="img-ratio img-ratio-3-2"
        src={ratio32}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
    </>
  )
}

// Image Ratio Amp
export const ImgRatioAmp = () => {
  return (
    <>
      <amp-img
        class="img-ratio img-ratio-1-1"
        src={ratio11}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <amp-img
        class="img-ratio img-ratio-2-1"
        src={ratio21}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
      <amp-img
        class="img-ratio img-ratio-3-2"
        src={ratio32}
        alt="Ratio Placeholder"
        width="100"
        height="100"
      />
    </>
  )
}

// Media Query
export const useMedia = query => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)
  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [query, matches])
  return matches
}

// Link
export const Link = props => {
  if (props.target === "_blank") {
    return (
      <a
        className={`btn btn-link ${props.className ? props.className : ""}`}
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    )
  } else if (props.target === "") {
    const rawUrl = props.to && props.to
    const config = "https://beryl-ks79.antikode.dev"

    const navigate = () => {
      const url = rawUrl && rawUrl.split(config)

      if (url && url.length > 1) {
        if (url[1] === "/home") {
          return "/"
        } else {
          return url[1]
        }
      } else if (url && url.length === 1) {
        return url[0]
      }
    }

    return (
      <GatsbyLink
        {...props}
        to={`${navigate()}`}
        className={props.className ? props.className : ""}
      >
        {props.children}
      </GatsbyLink>
    )
  } else {
    if (props.to && props.to.startsWith && props.to.startsWith("/")) {
      return <GatsbyLink {...props}>{props.children}</GatsbyLink>
    } else if (props.to === "") {
      return <div {...props}>{props.children}</div>
    } else {
      return (
        <a {...props} href={props.to} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )
    }
  }
}

// Background Image
export const BackgroundImage = props => {
  if (typeof props.fluid === "object") {
    return (
      <GatsbyBackgroundImage {...props}>{props.children}</GatsbyBackgroundImage>
    )
  } else {
    return (
      <div
        className={props.className}
        style={{
          background: `url(${props.fluid}) center center / cover no-repeat`,
        }}
      >
        {props.children}
      </div>
    )
  }
}

BackgroundImage.propTypes = {
  fluid: PropTypes.any,
  Tag: PropTypes.string,
  className: PropTypes.string,
}

BackgroundImage.defaultProps = {
  Tag: "div",
  className: "",
}

export const replaceUnicode = text =>
  text
    ?.replace(/(<([^>]+)>)/gi, "")
    ?.replace(/&#8211;/gi, "-")
    ?.replace(/&#038;/gi, "&")
    ?.replace(/&#8216;/gi, "'")
    ?.replace(/&#8221;/gi, '"')
    ?.replace(/&amp;/gi, "&")
