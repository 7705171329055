import React, { useContext } from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { LangContext } from "../../../context/LangContext"
import fbIconShare from "../../../assets/img/icons/facebook_2x.png"
import linkIconShare from "../../../assets/img/icons/link_2x.png"
import linkedinIconShare from "../../../assets/img/icons/linkedin_2x.png"
import twitterIconShare from "../../../assets/img/icons/twitter_2x.png"

export const SocialLinks = ({ data, className, isAmp }) => {
  return (
    <ul className={`social-links ${className}`}>
      {data.map((value, index) => {
        if (value.slug === "social-button") {
          return value.menuItems.nodes.map((value, index) => {
            if (isAmp) {
              switch (value.label.toLowerCase()) {
                case "fa-facebook-square":
                  return (
                    <li className="social-item" key={index}>
                      <a
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link footer-facebook-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                        </svg>
                      </a>
                    </li>
                  )
                case "fa-twitter-square":
                  return (
                    <li className="social-item" key={index}>
                      <a
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link footer-twitter-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                      </a>
                    </li>
                  )
                case "fa-instagram":
                  return (
                    <li className="social-item" key={index}>
                      <a
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link footer-instagram-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                        </svg>
                      </a>
                    </li>
                  )
                case "fa-youtube":
                  return (
                    <li className="social-item" key={index}>
                      <a
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link footer-youtube-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                        </svg>
                      </a>
                    </li>
                  )
                case "fa-tiktok":
                  return (
                    <li className="social-item" key={index}>
                      <a
                        href={value.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link footer-tiktok-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                        </svg>
                      </a>
                    </li>
                  )
                default:
                  return <div key={`empty-social-${index}`}></div>
              }
            } else {
              if (value.label === "fa-tiktok") {
                return (
                  <li className="social-item" key={index}>
                    <a
                      aria-label="tiktok social media"
                      href={value.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link footer-tiktok-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                      </svg>
                    </a>
                  </li>
                )
              } else if (value.label === "fa-twitter-square") {
                return (
                  <li className="social-item" key={index}>
                    <a
                      aria-label="tiktok social media"
                      href={value.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link footer-twitter-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    </a>
                  </li>
                )
              } else {
                return (
                  <li className="social-item" key={index}>
                    <a
                      aria-label={`${value.label} social media`}
                      href={value.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      <i className={`fab ${value.label}`}></i>
                    </a>
                  </li>
                )
              }
            }
          })
        }
      })}
    </ul>
  )
}

SocialLinks.propTypes = {
  className: PropTypes.string, // Set optional classes
  isAmp: PropTypes.bool,
}

SocialLinks.defaultProps = {
  className: "",
  isAmp: false,
}

export const SocialShare = ({ link, className, onClick, isAmp }) => {
  const { lang } = useContext(LangContext)
  const pathIdentifier = "/development/"
  if (link.indexOf(pathIdentifier) > -1) {
    return (
      <ul className={`social-links ${className}`}>
        <li className="social-item">
          <span className="share-caption mr-3">
            {lang === "EN" ? "Share:" : "Bagikan:"}
          </span>
        </li>
        <li className="social-item">
          <FacebookShareButton url={link} className="social-link">
            <img src={fbIconShare} />
          </FacebookShareButton>
        </li>
        <li className="social-item">
          <a id="copyButton" onClick={onClick} className="social-link">
            <img src={linkIconShare} />
          </a>
        </li>
        <li className="social-item">
          <LinkedinShareButton url={link} className="social-link">
            <img src={linkedinIconShare} />
          </LinkedinShareButton>
        </li>
        <li className="social-item">
          <TwitterShareButton url={link} className="social-link">
            <img src={twitterIconShare} />
          </TwitterShareButton>
        </li>
      </ul>
    )
  } else {
    if (isAmp) {
      return (
        <ul className={`social-links ${className}`}>
          <span
            className="share-caption mr-3"
            style={{
              color: "#2d3091",
              verticalAlign: "middle",
              display: "inline-block",
              paddingBottom: "10px",
              marginRight: "1rem",
            }}
          >
            {lang === "EN" ? "Share:" : "Bagikan:"}
          </span>
          <li className="social-item">
            <amp-social-share
              type="facebook"
              aria-label="Share on Facebook"
              width="25"
              height="25"
              data-param-app_id={process.env.FB_APP_ID}
            ></amp-social-share>
          </li>
          <li className="social-item">
            <amp-social-share
              type="twitter"
              aria-label="Share on Twitter"
              width="25"
              height="25"
            ></amp-social-share>
          </li>
          <li className="social-item">
            <amp-social-share
              type="linkedin"
              aria-label="Share on Linkedin"
              width="25"
              height="25"
            ></amp-social-share>
          </li>
          <li className="social-item">
            <a id="copyButton" onClick={onClick} className="social-link">
              <i className="fa fa-link"></i>
            </a>
          </li>
        </ul>
      )
    } else {
      return (
        <ul className={`social-links ${className}`}>
          <span
            className="share-caption mr-3"
            style={{
              color: "#2d3091",
              verticalAlign: "middle",
              display: "inline-block",
              paddingBottom: "10px",
            }}
          >
            {lang === "EN" ? "Share:" : "Bagikan:"}
          </span>
          <li className="social-item">
            <FacebookShareButton url={link} className="social-link">
              <i className="fab fa-facebook-square"></i>
            </FacebookShareButton>
          </li>
          <li className="social-item">
            <TwitterShareButton url={link} className="social-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </TwitterShareButton>
          </li>
          <li className="social-item">
            <LinkedinShareButton url={link} className="social-link">
              <i className="fab fa-linkedin"></i>
            </LinkedinShareButton>
          </li>
          <li className="social-item">
            <a id="copyButton" onClick={onClick} className="social-link">
              <i className="fa fa-link"></i>
            </a>
          </li>
        </ul>
      )
    }
  }
}

SocialShare.propTypes = {
  className: PropTypes.string, // Set optional classes
  link: PropTypes.string,
  isAmp: PropTypes.bool,
}

SocialShare.defaultProps = {
  className: "",
  link: "google.com",
  isAmp: false,
}
